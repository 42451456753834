import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql, Link } from "gatsby"
import SEO from "../components/seo"

export default function IndexPage() {
  const data = useStaticQuery(graphql`
    query {
      blogs: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 3
        filter: { frontmatter: { type: { eq: "blog" }, draft: { ne: true } } }
      ) {
        edges {
          node {
            excerpt(pruneLength: 250)
            html
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              type
            }
          }
        }
      }
      projects: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 2
        filter: {
          frontmatter: { type: { eq: "project" }, draft: { ne: true } }
        }
      ) {
        edges {
          node {
            excerpt(pruneLength: 250)
            html
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              type
            }
          }
        }
      }
      stories: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 2
        filter: { frontmatter: { type: { eq: "shorts" }, draft: { ne: true } } }
      ) {
        edges {
          node {
            excerpt(pruneLength: 250)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              type
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home"></SEO>
      <article className="card">
        <div class="center announcement">
          Welcome to Sasha Havoc's Website. Here you'll find information about
          my current projects, short stories and writing prompts, as well as
          hopefully weekly blog posts that include fun behind the scenes of my
          everyday life and writing.
        </div>
      </article>

      <article class="card">
        <h2 class="center block marker">Recent Blog Posts</h2>
        {data.blogs.edges.map(({ node }) => (
          <div class="">
            <h3>
              <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
            </h3>
            {node.frontmatter.date}
            <p>{node.excerpt}</p>
          </div>
        ))}
      </article>

      <article class="card">
        <h2 class="center block marker">Recent Shorts</h2>
        {data.stories.edges.map(({ node }) => (
          <div>
            <h3>
              <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
            </h3>
            <p>
              {node.excerpt}
              <Link to={node.frontmatter.path}>Keep reading</Link>
            </p>
          </div>
        ))}
      </article>

      <article class="card">
        <h2 class="center block marker">Current Projects</h2>
        {data.projects.edges.map(({ node }) => (
          <div>
            <h3>
              <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
            </h3>
            <p>
              {node.excerpt}
              <Link to={node.frontmatter.path}>Keep reading</Link>
            </p>
          </div>
        ))}
      </article>
    </Layout>
  )
}
